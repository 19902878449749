@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6b01c;
  color: #131112;
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.turtleHeader{
  background-color: #d34700;
}


.turtleButton {
  background-color: #f6b01c;
  border-radius: 10px;
}

.custom-img {
  background-image: url(/static/media/turtle.0510dde4.png);
}

.fade-in-section {
  opacity: 0;
  -webkit-transform: translateY(20vh);
          transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, -webkit-transform 1.2s ease-out;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out, -webkit-transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
  visibility: visible;
}

.linkText{
  text-decoration: underline;
  color:#f6b01c;
}
